
import { defineComponent } from "vue";
import SuperAdminPanelNavMenu from "@/components/molecules/SuperAdminPanelNavMenu.vue";
import Pagination from "@/components/molecules/Pagination.vue";
import View from "@/assets/svg/view.svg?inline";
import Edit from "@/assets/svg/edit.svg?inline";
import Delete from "@/assets/svg/delete.svg?inline";
import Picture from "@/assets/test/picture.svg?inline";
import Pluse from "@/assets/svg/pluse.svg?inline";
import Search from "@/assets/svg/search.svg?inline";
import SortArrow from "@/assets/svg/sort-arrow.svg?inline";
import DefaultPopup from "@/components/molecules/DefaultPopup.vue";
import AlertBlock from "@/components/molecules/AlertBlock.vue";
import Select from "@/components/atoms/CustomSelect.vue";

export default defineComponent({
  name: "SchoolAdmin",
  data() {
    return {
      page: 1,
      adminsData: {},
      adminsMeta: {},
      errorAdmins: null,
      searchData: "",
      schoolId: null,
      isModalDelete: false,
      errorSchool: null,
      dataPrograms: [],
      programId: "",
    };
  },
  components: {
    SuperAdminPanelNavMenu,
    Pagination,
    View,
    Edit,
    Delete,
    Picture,
    Pluse,
    Search,
    SortArrow,
    DefaultPopup,
    AlertBlock,
    Select,
  },
  watch: {
    searchData() {
      this.getSchoolList();
      this.page = 1;
    },
  },
  mounted() {
    this.getSchoolList();
    this.getPrograms();
  },
  methods: {
    getSchoolList(params, sort) {
      let searchParam = `?page=${this.page}&search=`;
      if (this.searchData !== "") {
        searchParam = `?page=${this.page}&search=` + this.searchData;
      }
      if (sort === undefined) {
        sort = "";
      }
      let filters = "";
      if (this.programId) {
        filters = "filter[program]" + this.programId;
      }
      params = searchParam + filters + "&sort=" + sort;
      this.$store.dispatch("admins/getSchoolList", params).then(
        (res) => {
          this.adminsData = res.data;
          this.adminsMeta = res.meta;
        },
        (error) => {
          this.errorAdmins = error.response.data.errors;
        }
      );
    },
    getPrograms(params) {
      params = "";
      this.$store.dispatch("admins/getPrograms", params).then(
        (res) => {
          this.dataPrograms = res.data;
        },
        (error) => {
          this.errorAdmins = error.response.data.errors;
        }
      );
    },
    impersonateSchool(id) {
      this.$store.dispatch("admins/impersonateSchool", id).then(
        (res) => {
          const tokenSA = localStorage.getItem("admin-sra");
          localStorage.setItem('financial-manager', JSON.stringify(true))
          localStorage.setItem('is-subscription-enabled', JSON.stringify(true))
          localStorage.setItem('admin-school-id', id)
          localStorage.setItem("super-admin-sra", tokenSA);
          localStorage.setItem("admin-sra", JSON.stringify(res.token));
          this.$router.push("/admin/analytics/home");
        },
        (error) => {
          this.errorAdmins = error.response.data.errors;
        }
      );
    },
    handlePage(page) {
      this.page = page;
      this.getSchoolList();
    },
    createSchool() {
      this.$router.push("/super-admin/create-school");
    },
    editSchool(id) {
      this.$router.push("/super-admin/create-school?edit=true&school=" + id);
    },
    deleteSchool() {
      this.$store.dispatch("admins/deleteSchool", this.schoolId).then(
        () => {
          this.getSchoolList();
          this.isModalDelete = false;
          this.schoolId = null;
        },
        (error) => {
          this.errorSchool = error.response.data;
        }
      );
    },
    viewSchool(id) {
      this.$router.push("/super-admin/school?school=" + id);
    },
    openDeletePoup(id) {
      this.schoolId = id;
      this.isModalDelete = true;
    },
    handleCloseModalDelete() {
      this.isModalDelete = false;
      this.schoolId = null;
      this.errorSchool = null;
    },
    handleOptionSelected(selectedOption) {
      this.programId = selectedOption.id;
      this.getSchoolList();
    },
  },
});
